import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Footer from './Footer'
import NavbarService from '../Navbar/NavbarService'
import './Contact.css'
import BassURl from '../Api/Api';
import { GiRotaryPhone } from "react-icons/gi";
function ContactUs() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${BassURl}api/contact-enquiry`, formData);
            
            // Handle successful form submission here
            console.log('Form submitted successfully');
            window.location.href = '/thank-you';
            // Optionally, reset the form fields
            setFormData({
                name: '',
                email: '',
                phone: '',
                subject: '',
                message: ''
            });
        } catch (error) {
            console.error('Error submitting form:', error);
            // Handle error here
        }
    };

    return (
        <>
            <NavbarService />
            {/* <div
                className="image-cover hero_banner "
                style={{ background: "url(/assets/img/uni-banner.jpg) no-repeat" }}
                data-overlay={4}
            ></div> */}
            <img src="/assets/img/uni-banner.png"  width="100%"  alt="" />
            <div className="contact ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="contact-page-form">
                                <h4>Feel Free To Make A Call Request Now</h4>
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Name"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="col-lg-6">
                                            <input
                                                type="email"
                                                className="form-control"
                                                placeholder="Email"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-lg-6">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Phone"
                                                name="phone"
                                                value={formData.phone}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="col-lg-6">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Subject"
                                                name="subject"
                                                value={formData.subject}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-lg-12">
                                            <textarea
                                                rows={6}
                                                className="form-control"
                                                placeholder="Message"
                                                name="message"
                                                value={formData.message}
                                                onChange={handleChange}
                                               
                                            />
                                            <button type="submit">Send Message</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="contact-text-area pl-20 pt-30">
                                <p className='text-justify'>At Aquamart, we understand that having access to clean, refreshing drinking water is essential. That's why we offer comprehensive water purifier service to ensure your system runs smoothly and delivers optimal performance.</p>

                                <h3 className='mb-3'>We're Here for All Your Water Purifier Needs:</h3>
                                <p className='text-justify'>
                                 <b>Installation:</b> Our certified technicians can expertly install your water purifier system, ensuring it's set up correctly for long-lasting efficiency.

                                </p>
                                <p className='text-justify'><b>Maintenance:</b> Regular maintenance is crucial for optimal water quality and system longevity. We offer flexible maintenance plans to fit your needs, with qualified technicians performing thorough cleaning, inspection, and parts replacement (if necessary).
</p>
                               <p className='text-justify'><b>Repairs:</b> If you encounter any issues with your water purifier, our technicians can diagnose the problem and provide prompt repairs using genuine brand spare parts.</p>

                                <h5>Opening Hours</h5>
                                <ul>
                                    <li>
                                        <span>Mon - Sun</span> <span style={{color:'#133f6d'}}>9:00am - 8:00pm</span>
                                    </li>
                                </ul>
                                <h5>Customer Support</h5>
                                <div className='m-0 d-flex align-items-center'><GiRotaryPhone style={{fontSize:'25px',color:'#133f6d'}}/><a href="tel:05224060673" className='ml-1'>  05224060673</a></div>
                                <p className='m-0'><a href="tel:7388188860"><i class="fa fa-phone" aria-hidden="true"></i> 7388188860</a></p>
                                <p className='m-0'><a href="tel:9936844403"><i class="fa fa-phone" aria-hidden="true"></i> 9936844403</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <Footer />




        </>
    )
}

export default ContactUs