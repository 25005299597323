import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import BassURl from "../Api/Api";
import NavbarService from "../Navbar/NavbarService";
import { addToServiceCartAction } from "../Redux/Action/CartAction";
import "./Service.css";
import ServiceAdd from "./ServiceAdd";
import parse from "html-react-parser";
import { PopupForm } from "../Model/PopupForm";

function Service() {
    const param = useParams();
    const [GetServiceCatgory, setGetServiceCatgory] = useState();
    useEffect(() => {
        axios
            .get(`${BassURl}api/service-category`)
            .then((res) => {
                setGetServiceCatgory(res.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const [serviceByCategory, setServiceByCategory] = useState("");
    useEffect(() => {
        axios
            .get(`${BassURl}api/service-by-category/${param.service_id}`)
            .then((res) => {
                setServiceByCategory(res.data.data.data);
            })
            .catch((erro) => {
                console.log(erro);
            });
    }, []);

    const [CategoryDetail, setCategoryDetail] = useState(null);
    useEffect(() => {
        axios
            .get(`${BassURl}api/service-category-details/${param.service_id}`)
            .then((res) => {
                setCategoryDetail(res.data);
                console.log(res.data);
            })
            .catch((erro) => {
                console.log(erro);
            });
    }, []);

    const addToServiceCartReducer = useSelector(
        (state) => state?.addToServiceCartReducer
    );
    const { cartItemService } = addToServiceCartReducer;
    const options = {
        replace: (domNode) => {
            if (domNode.attribs && domNode.attribs.class === "remove") {
                return <></>;
            }
        },
    };
    function removeNonBreakingSpaces(inputString) {
        return inputString.replace(/&nbsp;/g, " ");
    }
    const formatNumberWithCommas = (number) => {
        return new Intl.NumberFormat("en-US").format(number);
    };

    // Jagriti Start
    const [filterOpen, setFilterOpen] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState("All");
    const [selectedFilters, setSelectedFilters] = useState("All");
    const [inputText, setInputText] = useState("");
    const [filters, setFilters] = useState({
        color: "all",
        store: "all",
        category: [],
        rating: "all",
        price: "",
    });

    const toggleFilter = () => {
        setFilterOpen(!filterOpen);
    };

    const handleFilterSelection = (type, value) => {
        setSelectedFilter(value);
        setFilterOpen(false);
        setFilters({ ...filters, [type]: value });
    };

    // Rating Start
    const handleFilterSelections = (type, value) => {
        setSelectedFilters(value);
        setFilterOpen(false);
        setFilters({ ...filters, [type]: value });
    };
    // Rating End

    const handleInputChange = (e) => {
        setInputText(e.target.value.toLowerCase());
    };

    const handleCategoryChange = (e) => {
        const { checked, value } = e.target;
        let updatedCategories = [...filters.category];

        if (checked && !updatedCategories.includes(value)) {
            updatedCategories.push(value);
        } else {
            updatedCategories = updatedCategories.filter(
                (category) => category !== value
            );
        }

        setFilters({ ...filters, category: updatedCategories });
    };
    // Jagriti End

    // Priyanka Start
    const [selectedValue, setSelectedValue] = useState("");

    const handleSelectChange = (e) => {
        setSelectedValue(e.target.value);
    };
    // Priyanka End

    // Jyoti Start
    const [minAngle, setMinAngle] = useState(10);
    const [maxAngle, setMaxAngle] = useState(30);

    const handleMinChange = (event) => {
        let val = parseInt(event.target.value);
        if (val > maxAngle) {
            setMaxAngle(val);
        }
        setMinAngle(val);
    };

    const handleMaxChange = (event) => {
        let val = parseInt(event.target.value);
        if (val < minAngle) {
            setMinAngle(val);
        }
        setMaxAngle(val);
    };
    // Jyoti End

    const [ProductDetail, setProductDetail] = useState(null);
    useEffect(() => {
        axios
            .get(`${BassURl}api/shop-category-details/${param.id}`)
            .then((res) => {
                setProductDetail(res.data);
                console.log(res.data);
            })
            .catch((erro) => {
                console.log(erro);
            });
    }, []);

    return (
        <>
            <NavbarService />

            <div>
                {CategoryDetail && (
                    // Render actual data when CategoryDetail is truthy
                    <img
                        src={`${BassURl}${CategoryDetail.data.service_background}`}
                        width="100%"
                        alt="Category Image"
                    />
                )}
            </div>

            <section className="section-b-space shop-section">
                <div className="container-fluid-lg">
                    <div className="row">
                        <>
                            <div className="col-xxl-3 col-md-3 col-lg-3">
                                <div className="left-box inBox">
                                    <main
                                        className={`cd-main-content ₹{
                                    filterOpen ? "filter-is-visible" : ""
                                }`}
                                    >
                                        {/* Filter panel */}
                                        <div className="cd-filter">
                                            {/* Filter content */}
                                            <form>
                                                {/* Categories Brand filter */}
                                                <div className="cd-filter-block">
                                                    <h4 className="lne">
                                                        Brand
                                                    </h4>
                                                    <ul className="cd-filter-content cd-filters list check">
                                                        {/* Render categories dynamically */}
                                                        {[
                                                            "Aquqguard",
                                                            "Kent",
                                                            "Pureit",
                                                            "Bolt + COPPER",
                                                            "Livpure",
                                                            "Show All",
                                                        ].map((category) => (
                                                            <li key={category}>
                                                                <input
                                                                    type="checkbox"
                                                                    id={
                                                                        category
                                                                    }
                                                                    value={
                                                                        category
                                                                    }
                                                                    checked={filters.category.includes(
                                                                        category
                                                                    )}
                                                                    onChange={
                                                                        handleCategoryChange
                                                                    }
                                                                />{" "}
                                                                &nbsp;
                                                                <label
                                                                    htmlFor={
                                                                        category
                                                                    }
                                                                >
                                                                    {category}
                                                                </label>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                {/* Store filter */}
                                                <div className="cd-filter-block">
                                                    <h4 className="lne">
                                                        Discount
                                                    </h4>
                                                    <ul className="cd-filter-content cd-filters list">
                                                        <li>
                                                            <input
                                                                type="radio"
                                                                name="storeFilter"
                                                                id="allStore"
                                                                checked={
                                                                    selectedFilter ===
                                                                    "All"
                                                                }
                                                                onChange={() =>
                                                                    handleFilterSelection(
                                                                        "store",
                                                                        "All"
                                                                    )
                                                                }
                                                            />
                                                            <label htmlFor="allStore">
                                                                30% or more
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <input
                                                                type="radio"
                                                                name="storeFilter"
                                                                id="appleStore"
                                                                checked={
                                                                    selectedFilter ===
                                                                    "Apple Store"
                                                                }
                                                                onChange={() =>
                                                                    handleFilterSelection(
                                                                        "store",
                                                                        "Apple Store"
                                                                    )
                                                                }
                                                            />
                                                            <label htmlFor="appleStore">
                                                                40% or more
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <input
                                                                type="radio"
                                                                name="storeFilter"
                                                                id="amazon"
                                                                checked={
                                                                    selectedFilter ===
                                                                    "Amazon.com"
                                                                }
                                                                onChange={() =>
                                                                    handleFilterSelection(
                                                                        "store",
                                                                        "Amazon.com"
                                                                    )
                                                                }
                                                            />
                                                            <label htmlFor="amazon">
                                                                50% or more
                                                            </label>
                                                        </li>
                                                    </ul>
                                                </div>
                                                {/* Customer Rating */}
                                                <div className="cd-filter-block">
                                                    <h4 className="lne">
                                                        Customer Ratings
                                                    </h4>
                                                    <div class="checkbox-group">
                                                        <input
                                                            type="checkbox"
                                                            id="fiveStart"
                                                        />{" "}
                                                        &nbsp;
                                                        <label
                                                            for="fiveStart"
                                                            style={{
                                                                color: "#ff9800",
                                                            }}
                                                        >
                                                            ★★★★★
                                                        </label>
                                                    </div>
                                                    <div class="checkbox-group">
                                                        <input
                                                            type="checkbox"
                                                            id="fourStart"
                                                        />
                                                        &nbsp;
                                                        <label
                                                            for="fourStart"
                                                            style={{
                                                                color: "#ff9800",
                                                            }}
                                                        >
                                                            ★★★★
                                                        </label>
                                                    </div>
                                                    <div class="checkbox-group">
                                                        <input
                                                            type="checkbox"
                                                            id="threeStart"
                                                        />{" "}
                                                        &nbsp;
                                                        <label
                                                            for="threeStart"
                                                            style={{
                                                                color: "#ff9800",
                                                            }}
                                                        >
                                                            ★★★
                                                        </label>
                                                    </div>
                                                    <div class="checkbox-group">
                                                        <input
                                                            type="checkbox"
                                                            id="twoStart"
                                                        />{" "}
                                                        &nbsp;
                                                        <label
                                                            for="twoStart"
                                                            style={{
                                                                color: "#ff9800",
                                                            }}
                                                        >
                                                            ★★
                                                        </label>
                                                    </div>
                                                    <div class="checkbox-group">
                                                        <input
                                                            type="checkbox"
                                                            id="oneStart"
                                                        />{" "}
                                                        &nbsp;
                                                        <label
                                                            for="oneStart"
                                                            style={{
                                                                color: "#ff9800",
                                                            }}
                                                        >
                                                            ★
                                                        </label>
                                                    </div>
                                                </div>
                                                {/* price filter */}
                                                <div className="cd-filter-block">
                                                    <h4 className="lne">
                                                        Price
                                                    </h4>
                                                    {/* <input type="range" class="form-control-range" id="formControlRange"></input> */}
                                                    <div id="app">
                                                        <div className="range-slider">
                                                            <input
                                                                type="range"
                                                                min="0"
                                                                max="30000"
                                                                step="1"
                                                                value={minAngle}
                                                                onChange={
                                                                    handleMinChange
                                                                }
                                                            />
                                                            <input
                                                                type="range"
                                                                min="0"
                                                                max="30000"
                                                                step="1"
                                                                value={maxAngle}
                                                                onChange={
                                                                    handleMaxChange
                                                                }
                                                            />
                                                            <div></div>
                                                            <span className="rangePrice">
                                                                Price :
                                                            </span>{" "}
                                                            &nbsp; &nbsp;
                                                            <input
                                                                type="number"
                                                                min="0"
                                                                max="30000"
                                                                step="1"
                                                                value={minAngle}
                                                                onChange={
                                                                    handleMinChange
                                                                }
                                                                className="ran"
                                                            />
                                                            <input
                                                                type="number"
                                                                min="0"
                                                                max="30000"
                                                                step="1"
                                                                value={maxAngle}
                                                                onChange={
                                                                    handleMaxChange
                                                                }
                                                                className="ran"
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* <div className="cd-filter-content">
                                                            <div className="cd-select cd-filters">
                                                                <select
                                                                    value={
                                                                        filters.price
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        setFilters(
                                                                            {
                                                                                ...filters,
                                                                                price: e
                                                                                    .target
                                                                                    .value,
                                                                            }
                                                                        )
                                                                    }
                                                                    className="form-control check"
                                                                >
                                                                    <option value="">
                                                                        Under
                                                                        ₹10,000
                                                                    </option>
                                                                    <option value=".option1">
                                                                        10,000-15,000
                                                                    </option>
                                                                    <option value=".option2">
                                                                        15,000-20,000
                                                                    </option>
                                                                    <option value=".option3">
                                                                        20,000-25,000
                                                                    </option>
                                                                    <option value=".option4">
                                                                        25,000-30,000
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div> */}
                                                </div>
                                                <br />
                                            </form>
                                        </div>
                                    </main>
                                </div>
                            </div>
                        </>
                        <div className="col-xxl-9 col-lg-9">
                            <div className="show-button">
                            <div className="row">
                                    <div className="col-md-12">
                                        <div className="weFne">
                                            <span className="fnt">
                                                {" "}
                                                We found items for you!
                                            </span>
                                            <div className="lbl">
                                                <label className="fnt">
                                                    Sort By :
                                                </label>
                                                <div>
                                                    <select
                                                        class="wef"
                                                        aria-label="Default select example"
                                                    >
                                                        {" "}
                                                        <option selected="">
                                                            Featured
                                                        </option>
                                                        <option value="1">
                                                            a-z
                                                        </option>
                                                        <option value="2">
                                                            z-a
                                                        </option>
                                                        <option value="3">
                                                            High-Low
                                                        </option>
                                                        <option value="4">
                                                            Low-High
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="filter-button-group mt-0">
                                    <div className="filter-button d-inline-block d-lg-none">
                                        <a>
                                            <i className="fa-solid fa-filter" />{" "}
                                            Filter Menu
                                        </a>
                                    </div>
                                </div>
                                <div className="top-filter-menu"></div>
                            </div>
                            <div className="row product-list-section list-style">
                                {serviceByCategory &&
                                    serviceByCategory?.map((data) => {
                                        return (
                                            <>
                                                <div className="col-lg-4">
                                                    <div className="property-listing property-2 productHeight items applicanceDivBorder">
                                                        <div className="listing-img-wrapper">
                                                            <div className="list-img-slide">
                                                                <div className="click">
                                                                    <div>
                                                                        <a
                                                                            href={`/product/${data?.service_url}`}
                                                                        >
                                                                            <img
                                                                                src={`${BassURl}${data?.service_image}`}
                                                                                className="img-fluid imgProduct mx-auto"
                                                                                alt=""
                                                                            />
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="listing-detail-wrapper">
                                                            <div className="listing-short-detail-wrap">
                                                                <div className="_card_list_flex">
                                                                    <div className="_card_flex_01">
                                                                        <h4 className="listing-name verified">
                                                                            <a
                                                                                href={`/product/${data?.service_url}`}
                                                                                className="prt-link-detail"
                                                                            >
                                                                                {
                                                                                    data?.service_title
                                                                                }
                                                                            </a>
                                                                        </h4>
                                                                        <div
                                                                            className="proudctCardDes"
                                                                            style={{
                                                                                color: "#fff",
                                                                            }}
                                                                        >
                                                                            {parse(
                                                                                removeNonBreakingSpaces(
                                                                                    data?.description
                                                                                )
                                                                            )}
                                                                        </div>

                                                                        {/* <p className=""> </p> */}
                                                                    </div>
                                                                    <br />
                                                                </div>
                                                                <div
                                                                    className="footer-first"
                                                                    style={{
                                                                        display:
                                                                            "flex",
                                                                        marginTop:
                                                                            "12px",
                                                                    }}
                                                                >
                                                                    <div className="foot-rates">
                                                                        <span className="elio_rate good">
                                                                            4.2
                                                                        </span>
                                                                        <div className="_rate_stio">
                                                                            <i className="fa fa-star" />
                                                                            <i className="fa fa-star" />
                                                                            <i className="fa fa-star" />
                                                                            <i className="fa fa-star" />
                                                                            <i className="fa fa-star" />
                                                                        </div>
                                                                    </div>

                                                                    <div className="footer-first">
                                                                        <h6 className="listing-card-info-price mb-0  price-style">
                                                                            ₹
                                                                            &nbsp;
                                                                            {formatNumberWithCommas(
                                                                                data?.service_price
                                                                            )}
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="listing-detail-footer">
                                                            <div className="footer-flex ">
                                                                {/* <ServiceAdd
                                                                    data={data}
                                                                /> */}
                                                                <PopupForm/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        );
                                    })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* {
    GetServiceCatgory && GetServiceCatgory?.map((data)=>{
      if(data?.service_url === param?.service_url){

      }
    })
   } */}
        </>
    );
}

export default Service;
