import React, {useState, useEffect} from 'react'
import axios from 'axios';
import BassURl from '../Api/Api';

function Footer() {
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [flag, setFlag] = useState(1);

    useEffect(() => {
        const getData = async () => {
          try {
            const response = await axios.get(
              `${BassURl}api/appliances-shop`
            );
            setProduct(response.data.data);
            setError(null);
          } catch (err) {
            setError(err.message);
            setProduct(null);
          } finally {
            setLoading(false);
          }
        };
        getData();
      }, []);

    return (
        <>
            {/* ============================ Footer Start ================================== */}
            <footer className="dark-footer skin-dark-footer style-2">
            
                {/*Header ends*/}
                <div className="footer-middle">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 col-md-5">
                                <div className="footer_widget">
                                    <img
                                        src="assets/img/logo.webp"
                                        className="img-footer small mb-2"
                                        alt=""
                                    />
                                    <p className='text-light text-justify'>
                                    Water purifiers are essential appliances that ensure that our drinking water is clean and safe. However, even the best water purifiers can break down over time. That's where AQUAMART comes in. AQUAMART is one of the Trusted & Independent Water Purifier Service Provider. We offer Ro Installation, Repair & comprehensive water purifier repair services for all Major brands and models.
                                    </p>
                                    <div className="foot-news-last">
                                        {/* <div className="input-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Email Address"
                                            />
                                            <div className="input-group-append">
                                                <button
                                                    type="button"
                                                    className="input-group-text theme-bg b-0 text-light"
                                                >
                                                    Subscribe
                                                </button>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-7 ml-auto">
                                <div className="row">
                                    <div class="col-md-1"></div>
                                    <div className="col-lg-3 col-md-3">
                                        <div className="footer_widget">
                                            <h4 className="widget_title">Quick Link</h4>
                                            <ul className="footer-menu">
                                                <li>
                                                    <a href="/">Home</a>
                                                </li>
                                                <li>
                                                    <a href="/about">About Us</a>
                                                </li>
                                                <li>
                                                    <a href="/contact-us">Contact Us</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="col-lg-3 col-md-3">
                                        <div className="footer_widget">
                                            <h4 className="widget_title">Appliances</h4>
                                            <ul className="footer-menu">
                                                {product &&
                                                    product?.map((item, index) => {
                                                        return (
                                                        <>
                                                            <li>

                                                            <a href={`/appliances/${item.category_url}`}
                                                                key={index}
                                                                style={{ textTransform: "capitalize" }}
                                                            >
                                                                {item?.category_title}
                                                            </a>
                                                            </li>
                                                        </>
                                                        );
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="col-lg-5 col-md-5">
                                        <div className="footer_widget">
                                            <h4 className="widget_title">Contact Us</h4>
                                            <ul className="footer-menu">
                                                <li><i class="fas fa-map-marker-alt" style={{color: "#00a0e3"}}></i>  
                                                &nbsp; &nbsp;
                                                    <a href="#">Near Pantaloon outlet,  engineering college chouraha, Aliganj, lucknow</a>
                                                </li>
                                                <li><i class="fas fa-phone" style={{ color: "#00a0e3" }}></i> &nbsp;
                                                    <a href="tel:+919696361300">+91 9696361300</a>
                                                </li>
                                                <li><i class="far fa-envelope-open"  style={{ color: "#00a0e3" }}></i>  
                                                &nbsp; &nbsp;
                                                    <a href="mailto:Care@aquamart.co.in">Care@aquamart.co.in</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-12 col-md-12 text-center">
                                <p className="mb-0">
                                    © 2022  Designd By     {" "}
                                    <a href="https://www.digitalnawab.com/"><i className='fa fa-heart'></i> </a>
                                    <span style={{ color: "#00a0e3" }}>Digital Nawab</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {/* ============================ Footer End ================================== */}
        </>
    )
}

export default Footer